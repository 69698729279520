import { ILocalisationConfig } from "./localisation.interface";
import defaults from "./localisation-defaults";
import au from "./localisation-au";
import nz from "./localisation-nz";
import ie from "./localisation-ie";
import { E_PatientDetailsSection } from "../../backend/src/graph/patient-details/patient-details-base";

export const SUPPORTED_ISO_COUNTRY_CODES = ["AU", "CA", "GB", "GG", "IE", "IM", "JE", "NZ"] as const;
export type SupportedCountryCode = (typeof SUPPORTED_ISO_COUNTRY_CODES)[number];
export function isSupportedCountryCode(c: string): c is SupportedCountryCode {
  return (SUPPORTED_ISO_COUNTRY_CODES as readonly string[]).includes(c);
}

const Localisation: Record<string, ILocalisationConfig> = {
  defaults,
  au,
  nz,
  ie,
};

export const requiredAddressFields = (country_code: SupportedCountryCode) => {
  let { requiredContactFields } = Localisation[country_code.toLowerCase()] || Localisation.defaults;

  if (!(E_PatientDetailsSection.Address in requiredContactFields)) {
    ({ requiredContactFields } = Localisation.defaults);
  }

  return requiredContactFields[E_PatientDetailsSection.Address];
};
