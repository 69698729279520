import { E_PatientDetailsSection } from "../../backend/src/graph/patient-details/patient-details-base";
import { ILocalisationConfig } from "./localisation.interface";

const au: ILocalisationConfig = {
  requiredContactFields: {
    [E_PatientDetailsSection.Address]: ["address_line_1", "address_line_3", "county", "postcode"],
  },
};

export default au;
