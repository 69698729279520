import { E_PatientDetailsSection } from "../../backend/src/graph/patient-details/patient-details-base";
import { ILocalisationConfig } from "./localisation.interface";

const defaults: ILocalisationConfig = {
  requiredContactFields: {
    [E_PatientDetailsSection.Address]: ["address_line_1", "town", "county"],
  },
};

export default defaults;
