import forOwn from "lodash/forOwn";
import { PracticeBase } from "../../../../../backend/src/graph/practices/practice-base";
import { stripWhitespace } from "@shared/utils";
import { AppointmentSettingsEntry } from "./appointment-settings";
import { BrandInfoEntry } from "./brand-info";
import { AcquisitionSourceEntry } from "./acquisition-source";
import { BaseListBase } from "../../../../../backend/src/graph/_resolvers/base/base-list-base";
import { SmileGoalEntry } from "./smile-goals";

export class PracticeEntry extends PracticeBase {
  public appointment_settings: AppointmentSettingsEntry;
  public acquisition_sources: BaseListBase<AcquisitionSourceEntry>;
  public smile_goals: SmileGoalEntry;
  public brand: BrandInfoEntry;

  constructor(baseItem?: PracticeBase) {
    super();
    if (!baseItem) return;
    forOwn(baseItem, (value, key) => {
      if (key === "phone_number") {
        this.phone_number = stripWhitespace(value as string);
      } else {
        this[key] = value;
      }
    });
  }
}
