class SmileGoalsCommon {
  value: number;
  heading: string;
}

export class SmileGoalsMotivationBase extends SmileGoalsCommon {}

export class SmileGoalsTimeframeBase extends SmileGoalsCommon {}

export class SmileGoalsServiceBase extends SmileGoalsCommon {
  motivations: Array<SmileGoalsMotivationBase>;
  description?: string;
}

export class SmileGoalsBase {
  goals: Array<SmileGoalsServiceBase>;
  timeframes: Array<SmileGoalsTimeframeBase>;
}

export const mock_data: SmileGoalsBase = {
  goals: [
    {
      heading: "To Be Pain-Free",
      description: "Relief from tooth and gum pain so I can feel comfortable again.",

      motivations: [
        { heading: "Overcoming daily discomfort", value: 0 },
        { heading: "To enjoy eating and drinking again", value: 1 },
        { heading: "Improving overall quality of life", value: 2 },
        { heading: "Preventing it from getting worse", value: 3 },
      ],
      value: 0,
    },
    {
      heading: "Whiter Teeth",
      description: "Brighten my teeth by removing stains so my smile looks fresh and vibrant",

      motivations: [
        { heading: "Upcoming special event", value: 0 },
        { heading: "Boost in confidence", value: 1 },
        { heading: "Professional reasons", value: 2 },
        { heading: "Just because I deserve it", value: 3 },
      ],
      value: 1,
    },
    {
      heading: "Straighter Teeth",
      description: "Align teeth so they look straight, even, and natural.",

      motivations: [
        { heading: "Upcoming special event", value: 0 },
        { heading: "Boost in confidence", value: 1 },
        { heading: "Professional reasons", value: 2 },
        { heading: "Just because I deserve it", value: 3 },
      ],
      value: 2,
    },
    {
      heading: "Fix Damaged Teeth",
      description: "Repair my chipped, cracked or broken teeth so I can smile confidently and chew comfortably.",

      motivations: [
        { heading: "Comfortable chewing and eating", value: 0 },
        { heading: "Preventing pain and further issues", value: 1 },
        { heading: "Professional reasons", value: 2 },
        { heading: "Improving the appearance", value: 3 },
        { heading: "Just because I deserve it", value: 4 },
      ],
      value: 3,
    },
    {
      heading: "Fill Gaps in My Smile",
      description: "Replace missing teeth or close gaps so my smile feels complete and functional.",

      motivations: [
        { heading: "Confidence when smiling or laughing", value: 0 },
        { heading: "Improving bite functionality", value: 1 },
        { heading: "Upcoming special event", value: 2 },
        { heading: "Improving teeth alignment", value: 3 },
        { heading: "Just because I deserve it", value: 4 },
      ],
      value: 4,
    },
    {
      heading: "Keep My Smile as It Is",
      description: "Maintain my teeth and gums to prevent problems and keep everything just as it is now.",

      motivations: [
        { heading: "I'm happy with my current smile", value: 0 },
        { heading: "Avoid unnecessary procedures", value: 1 },
        { heading: "Practical reasons of time or budget", value: 2 },
        { heading: "Just because I deserve it", value: 3 },
      ],
      value: 5,
    },
  ],
  timeframes: [
    {
      heading: "As Soon As Possible",
      value: 0,
    },
    {
      heading: "Weeks",
      value: 1,
    },
    {
      heading: "Months",
      value: 2,
    },
    {
      heading: "No Rush",
      value: 3,
    },
  ],
};
