class SmileGoalsCommon {
  value: number;
  heading: string;
}

export class SmileGoalsMotivationBase extends SmileGoalsCommon {}

export class SmileGoalsTimeframeBase extends SmileGoalsCommon {}

export class SmileGoalsServiceBase extends SmileGoalsCommon {
  motivations: Array<SmileGoalsMotivationBase>;
  description?: string;
}

export class SmileGoalsBase {
  goals: Array<SmileGoalsServiceBase>;
  timeframes: Array<SmileGoalsTimeframeBase>;
}
