import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import Bugsnag from "@bugsnag/js";
import { notifyAndLeaveBreadcrumb } from "../utils/logging";

const ALREADY_LOGGED_KEY = "alreadyLogged";

@Injectable({ providedIn: "root" })
export class LoggerService {
  public log(...msgs): boolean {
    return this._guardedLog("info", ...msgs);
  }

  public error(...msgs): boolean {
    try {
      Bugsnag.notify(new Error(msgs.toString()));
    } catch (e) {
      Bugsnag.notify(new Error("Error tc"));
    }
    return this._guardedLog("error", ...msgs);
  }

  public debug(...msgs): boolean {
    return this._guardedLog("debug", ...msgs);
  }

  public notifyBugsnagOnce(code: string, message: string, data?: Record<string, any>): void {
    this.log(message, data);

    const alreadyLogged = JSON.parse(localStorage.getItem(ALREADY_LOGGED_KEY) || "{}"); // Use local storage directly instead of CacheService to prevent circular dependency

    if (alreadyLogged[code]) {
      return;
    }

    notifyAndLeaveBreadcrumb(message, data);

    alreadyLogged[code] = true;

    localStorage.setItem(ALREADY_LOGGED_KEY, JSON.stringify(alreadyLogged));
  }

  private _guardedLog(type: string, ...msgs: string[]): boolean {
    if (environment.production === true) {
      return false;
    }
    if (typeof console[type] !== "function") {
      throw new Error(`Logger method '${type}' does not exist.`);
    }
    console[type](`[${type.toUpperCase()}]`, ...msgs);

    return true;
  }
}
