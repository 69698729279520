import { PatientMedicalHistoryBase } from "../../../../../backend/src/graph/patient_medical_histories/patient-medical-history-base";
import {
  MedicalHistoryQuestionsBase,
  PatientMedicalHistoryQuestionBase,
} from "../../../../../backend/src/graph/patient_medical_histories/medical-history-questions-base";
import forOwn from "lodash/forOwn";
import { PatientBase } from "@backend/graph/patients/patient-base";
import { Subject } from "rxjs";

export class MedicalHistoryQuestionEntry extends PatientMedicalHistoryQuestionBase {
  public show = true;
  public onQuestionConfirmed = new Subject<boolean>();

  public toBase(): PatientMedicalHistoryQuestionBase {
    const newBase = new PatientMedicalHistoryQuestionBase();
    newBase.details = this.details;
    newBase.follow_up_question = this.follow_up_question;
    newBase.position = this.position;
    newBase.question_id = this.question_id;
    newBase.text = this.text;
    newBase.value = this.value;
    return newBase;
  }

  constructor(baseItem?: PatientMedicalHistoryQuestionBase) {
    super();
    if (!baseItem) return;
    forOwn(baseItem, (value, key) => {
      if (key === "details") this.details = String(value || "");
      else this[key] = value;
    });
  }

  public set confirmed(confirmed: boolean) {
    this.onQuestionConfirmed.next(confirmed);
  }
}

export class MedicalHistoryEntry extends PatientMedicalHistoryBase {
  public date_of_birth: string;
  public age: number;
  public questions: Array<MedicalHistoryQuestionEntry>;

  public get questions_with_yes_answers(): Array<PatientMedicalHistoryQuestionBase> {
    return this.questions.filter((question) => question.value === "True");
  }

  constructor(baseItem?: PatientMedicalHistoryBase, patientItem?: PatientBase) {
    super();
    if (baseItem) {
      forOwn(baseItem, (value, key) => {
        this[key] = value;
      });
    }

    if (this.questions) {
      const entries = new Array<MedicalHistoryQuestionEntry>();
      for (const question of this.questions) {
        const entry = new MedicalHistoryQuestionEntry(question);
        entries.push(entry);
      }
      this.questions = entries;
    }

    if (patientItem) {
      this.date_of_birth = patientItem.date_of_birth;
      this.age = patientItem.age;
    }
  }
}

export class MedicalHistoryQuestionsEntry extends MedicalHistoryQuestionsBase {
  public dateOfBirth: string;
  public questions: Array<MedicalHistoryQuestionEntry>;
  public encoded_signature: string;
  public signature_used: string;

  constructor(baseItem?: MedicalHistoryQuestionsBase) {
    super();
    if (!baseItem) return;
    forOwn(baseItem, (value, key) => {
      this[key] = value;
    });

    this.questions = new Array<MedicalHistoryQuestionEntry>();
    for (const question of baseItem.questions) {
      this.questions.push(new MedicalHistoryQuestionEntry(question));
    }
  }
}
