import { Injectable } from "@angular/core";
import { LoggerService } from "./logger.service";
import { ICacheStorage } from "../interfaces/cache-storage.interface";
import { BrowserStorageFactory } from "../utils/browser-storage.factory";
import { E_StorageType } from "../utils/local-storage.util";
import { notifyAndLeaveBreadcrumb } from "../utils/logging";

export const ALREADY_LOGGED_PERSIST_ERROR_KEY = "alreadyLoggedPersistError";

@Injectable({
  providedIn: "root",
})
export class CacheService {
  private _localStorage: ICacheStorage;
  private _sessionStorage: ICacheStorage;

  public constructor(private _loggerService: LoggerService, browserStorageFactory: BrowserStorageFactory) {
    this._localStorage = browserStorageFactory.create(E_StorageType.LOCAL_STORAGE);
    this._sessionStorage = browserStorageFactory.create(E_StorageType.SESSION_STORAGE);
  }

  public set(key: string, value: any, storage = this._localStorage): void {
    this._loggerService.debug(`Caching payload with cache key '${key}':`, value);
    storage.setItem(key, value);
  }

  public get(key: string, storage = this._localStorage): string | null {
    const result = storage.getItem(key);

    if (result?.constructor === Object && Object.keys(result).length === 0) {
      this._loggerService.debug(`No cached data found for '${key}'.`);
      return null;
    }

    return result;
  }

  public getJson<T>(key: string, storage = this._localStorage): T | null {
    const jsonString = this.get(key, storage);

    if (!jsonString) return null;

    try {
      const data = JSON.parse(jsonString);

      if (data.ttl && data.ttl < Date.now()) {
        this.deleteSession(key);
        return null;
      }

      return data.value;
    } catch (e) {
      console.error("error parsing storage", { key, storageStr: jsonString }, e);

      return null;
    }
  }

  public getRelativeTtl(ttl: number): number {
    return Date.now() + ttl;
  }

  public setJson<T extends Record<string, any>>(key: string, value: T, ttl?: number, storage = this._localStorage): void {
    const data: Record<string, any> = { value };

    if (ttl) {
      data.ttl = ttl;
    }

    const jsonString = JSON.stringify(data);

    this.set(key, jsonString, storage);
  }

  public delete(key: string, storage = this._localStorage): void {
    storage.removeItem(key);
  }

  public getJsonSession<T>(key: string): T | null {
    return this.getJson(key, this._sessionStorage);
  }

  public setJsonSession(key: string, value: Record<string, any>, ttl?: number): void {
    return this.setJson(key, value, ttl, this._sessionStorage);
  }

  public setSession(key: string, value: any): void {
    this.set(key, value, this._sessionStorage);
  }

  public getSession(key: string): string | null {
    return this.get(key, this._sessionStorage);
  }

  public deleteSession(key: string): void {
    this.delete(key, this._sessionStorage);
  }

  public clearSession(): void {
    this._sessionStorage.clear();
  }

  public clear(): void {
    this._localStorage.clear();
  }

  public async persist(): Promise<void> {
    try {
      const isPersisted = await navigator.storage.persisted();

      if (isPersisted) {
        return;
      }

      const persistGranted = await navigator.storage.persist();

      if (persistGranted) {
        return;
      }

      throw new Error("Browser denied persisting storage");
    } catch (error) {
      const alreadyLogged = this._localStorage.getItem(ALREADY_LOGGED_PERSIST_ERROR_KEY) === "true";

      if (alreadyLogged) {
        return;
      }

      notifyAndLeaveBreadcrumb("Failed to persist storage", { userAgent: navigator.userAgent, error: error.message });

      this._localStorage.setItem(ALREADY_LOGGED_PERSIST_ERROR_KEY, "true");
    }
  }
}
