import { SmileGoalsMotivationBase, SmileGoalsServiceBase, SmileGoalsTimeframeBase } from "@backend/graph/smile-goals/smile-goals-base";
import forOwn from "lodash/forOwn";

export class SmileGoalEntry {
  goals: Array<SmileGoalsServiceEntry>;
  timeframes: Array<SmileGoalsMotivationEntry>;
}

export class SmileGoalsServiceEntry extends SmileGoalsServiceBase {
  constructor(baseItem?: SmileGoalsServiceBase) {
    super();
    if (!baseItem) return;
    forOwn(baseItem, (value, key) => {
      this[key] = value;
    });
  }
}

export class SmileGoalsMotivationEntry extends SmileGoalsMotivationBase {
  constructor(baseItem?: SmileGoalsMotivationBase) {
    super();
    if (!baseItem) return;
    forOwn(baseItem, (value, key) => {
      this[key] = value;
    });
  }
}

export class SmileGoalsTimeframeEntry extends SmileGoalsTimeframeBase {
  constructor(baseItem?: SmileGoalsTimeframeBase) {
    super();
    if (!baseItem) return;
    forOwn(baseItem, (value, key) => {
      this[key] = value;
    });
  }
}
